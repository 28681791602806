.btnContainer {
  border-radius: 18px;
  border: 3px solid black;
  font-weight: 900;
  color: white;
  line-height: 0.9;
  font-size: 16px;
  &.rounded {
    border-radius: 999px;
    font-size: 10px;
  }
  .inner {
    height: 100%;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 11px 16px;
    transition: color 0.2s;
    background: linear-gradient(180deg, #707284 -0.37%, #454651 99.94%) padding-box,
      linear-gradient(0deg, #707284 -0.37%, #454651 99.94%) border-box;
    border-radius: 12px;
    border: 2px solid transparent;
    &:hover {
      color: var(--color-contrast-high);
    }

    &.timer {
      width: 61px;
      height: 61px;
      padding: 6px;
    }
    &.blue {
      min-height: 44px;
      min-width: 44px;
      padding: 6px;
      background: linear-gradient(180deg, #67abe8 -0.37%, #2c6394 99.94%) padding-box,
        linear-gradient(0deg, #67abe8 -0.37%, #2c6394 99.94%) border-box;
    }
    &.purple {
      min-height: 44px;
      min-width: 44px;
      padding: 6px;
      background: linear-gradient(180deg, #6665d2 -0.37%, #3c3b98 99.94%) padding-box,
        linear-gradient(0deg, #6665d2 -0.37%, #3c3b98 99.94%) border-box;
    }

    &.bronze {
      text-transform: uppercase;
      min-height: 44px;
      min-width: 44px;
      padding: 10px 14px;
      background: linear-gradient(180deg, #eaa800 0%, #7e3500 100%) padding-box,
        linear-gradient(0deg, #eaa800 0%, #7e3500 100%) border-box;
    }
    &.green {
      text-transform: uppercase;
      min-height: 44px;
      min-width: 44px;
      padding: 10px 14px;
      background: linear-gradient(180deg, #0B450A 0%, #62804B 100%) padding-box,
      linear-gradient(0deg, #0B450A 0%, #62804B 100%) border-box;
    }
    &.dark {
      text-transform: uppercase;
      min-height: 44px;
      min-width: 44px;
      padding: 10px 14px;
      background: linear-gradient(180deg, #111111 0%, #282828 100%) padding-box,
      linear-gradient(0deg, #111111 0%, #282828 100%) border-box;
    }


    &.bronzeSmall {
      text-transform: uppercase;
      border-radius: 999px;
      padding: 8px 10px;
      background: linear-gradient(180deg, #eaa800 0%, #7e3500 100%) padding-box,
        linear-gradient(0deg, #eaa800 0%, #7e3500 100%) border-box;
    }
    &.bronzePrice {
      text-transform: uppercase;
      width: 100%;
      min-height: 40px;
      min-width: 44px;
      padding: 0;
      background: linear-gradient(180deg, #eaa800 0%, #7e3500 100%) padding-box,
        linear-gradient(0deg, #eaa800 0%, #7e3500 100%) border-box;
    }
    &.slideNav {
      font-size: 16px;
      text-transform: uppercase;
      height: 40px;
      width: 40px;
      padding: 0;
      background: linear-gradient(180deg, #eaa800 0%, #7e3500 100%) padding-box,
        linear-gradient(0deg, #eaa800 0%, #7e3500 100%) border-box;
    }
    &.footerBlue {
      height: 32px;
      width: 32px;
      padding: 4px;
      background: linear-gradient(180deg, #67abe8 -0.37%, #2c6394 99.94%) padding-box,
        linear-gradient(0deg, #67abe8 -0.37%, #2c6394 99.94%) border-box;
    }
    &.footerPurple {
      height: 32px;
      width: 32px;
      padding: 4px;
      background: linear-gradient(180deg, #6665d2 -0.37%, #3c3b98 99.94%) padding-box,
        linear-gradient(0deg, #6665d2 -0.37%, #3c3b98 99.94%) border-box;
    }
    &.black {
      text-transform: uppercase;
      min-height: 44px;
      min-width: 44px;
      padding: 20px 24px;
      background: linear-gradient(180deg, #3c3d47 0%, #000000 100%) padding-box,
        linear-gradient(0deg, #3c3d47 0%, #000000 100%) border-box;
    }
  }
  @media (min-width: 1024px) {
    border: 4px solid black;
    .inner {
      border: 3px solid transparent;
    }
  }
}
