.root {
  @apply mb-20;
  @media (min-width: 1024px) {
    margin-bottom: 100px;
  }
}
.bonusWrapper {
  @apply flex flex-col items-center gap-4 self-center;
  &:hover {
    .card {
      scale: 0.8;
      .inner {
        background: linear-gradient(180deg, #eaa800 0%, #7e3500 100%) padding-box,
          linear-gradient(0deg, #eaa800 0%, #7e3500 100%) border-box;
      }
    }
    .caption {
      transform: translateY(-12px);
    }
    .about {
      opacity: 1;
    }
  }
}
.card {
  width: 72px;
  @apply transition-all;
  // padding-bottom: calc(100% - 6px);
  border-radius: 12px;
  border: 3px solid black;
  position: relative;
  height: 72px;

  .inner {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    transition: color 0.2s;
    background: linear-gradient(180deg, #707284 -0.37%, #454651 99.94%) padding-box,
      linear-gradient(0deg, #707284 -0.37%, #454651 99.94%) border-box;
    border-radius: 8px;
    border: 2px solid transparent;
  }
}
.caption {
  @apply text-white font-black uppercase leading-none transition-all relative text-center;
}
.about {
  @apply absolute top-full left-1/2 z-10 w-full text-center text-xs text-white  normal-case italic font-normal;
  transform: translateX(-50%) translateY(8px);
  opacity: 0;
  pointer-events: none;
}
.icon {
  width: 60%;
  height: auto;
  position: absolute;
  inset: 50% 0 0 50%;
  transform: translateX(-50%) translateY(-50%);
}
