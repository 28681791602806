.root {
  position: relative;
  height: 100%;
  padding-top: 192px;

  @apply relative h-full flex flex-col items-center;
  padding-bottom: 112px;
  .bg {
    @apply w-full h-full object-cover relative;
  }
  .motto {
    @apply relative text-white  text-center font-black uppercase;
    font-size: 40px;
    line-height: 36px;
  }
  .subheading {
    @apply text-brown-300 italic text-base  lg:text-lg relative mt-2 mb-4 text-center;
  }
  @media (min-width: 1024px) {
    .motto {
      font-size: 56px;
      line-height: 50px;
    }
    .subheading {
      @apply text-lg;
    }
  }
  @media (min-width: 1280px) {
    padding-top: 244px;

    .bg {
      max-height: 850px;
    }
    .motto {
      font-size: 64px;
      line-height: 55px;
    }
    .subheading {
      @apply text-lg;
    }
  }
}
