@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Mont";
  src: url("./fonts/Mont-Heavy.woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Mont";
  src: url("./fonts/Mont-Regular-Italic.woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Mont";
  src: url("./fonts/Mont-Regular.woff2");
  font-weight: 400;
  font-style: normal;
}
body {
  font-family: "Mont";
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  background: #da903f;
}
.wrap {
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
}
@media (min-width: 1024px) {
  .wrap {
    padding: 0;
    max-width: 836px;
  }
}

@media (min-width: 1280px) {
  .wrap {
    max-width: calc(1024px);
  }
}
.stroke {
  text-shadow: rgb(157, 96, 28) 7px 0px 0px, rgb(157, 96, 28) 6.92869px 0.996602px 0px,
    rgb(157, 96, 28) 6.71622px 1.9729px 0px, rgb(157, 96, 28) 6.36692px 2.909px 0px,
    rgb(157, 96, 28) 5.8879px 3.78584px 0px, rgb(157, 96, 28) 5.28893px 4.58555px 0px,
    rgb(157, 96, 28) 4.5822px 5.29183px 0px, rgb(157, 96, 28) 3.78212px 5.8903px 0px,
    rgb(157, 96, 28) 2.90498px 6.36876px 0px, rgb(157, 96, 28) 1.96865px 6.71747px 0px,
    rgb(157, 96, 28) 0.992221px 6.92932px 0px, rgb(157, 96, 28) -0.00442571px 7px 0px,
    rgb(157, 96, 28) -1.00098px 6.92806px 0px, rgb(157, 96, 28) -1.97715px 6.71498px 0px,
    rgb(157, 96, 28) -2.91303px 6.36508px 0px, rgb(157, 96, 28) -3.78956px 5.88551px 0px,
    rgb(157, 96, 28) -4.58889px 5.28603px 0px, rgb(157, 96, 28) -5.29472px 4.57885px 0px,
    rgb(157, 96, 28) -5.89269px 3.77839px 0px, rgb(157, 96, 28) -6.3706px 2.90095px 0px,
    rgb(157, 96, 28) -6.71871px 1.96441px 0px, rgb(157, 96, 28) -6.92995px 0.98784px 0px,
    rgb(157, 96, 28) -6.99999px -0.00885142px 0px, rgb(157, 96, 28) -6.92743px -1.00536px 0px,
    rgb(157, 96, 28) -6.71372px -1.98139px 0px, rgb(157, 96, 28) -6.36324px -2.91705px 0px,
    rgb(157, 96, 28) -5.88311px -3.79328px 0px, rgb(157, 96, 28) -5.28313px -4.59223px 0px,
    rgb(157, 96, 28) -4.57551px -5.29762px 0px, rgb(157, 96, 28) -3.77466px -5.89507px 0px,
    rgb(157, 96, 28) -2.89692px -6.37243px 0px, rgb(157, 96, 28) -1.96016px -6.71995px 0px,
    rgb(157, 96, 28) -0.983458px -6.93057px 0px, rgb(157, 96, 28) 0.0132771px -6.99999px 0px,
    rgb(157, 96, 28) 1.00974px -6.92679px 0px, rgb(157, 96, 28) 1.98564px -6.71247px 0px,
    rgb(157, 96, 28) 2.92107px -6.36139px 0px, rgb(157, 96, 28) 3.797px -5.88071px 0px,
    rgb(157, 96, 28) 4.59557px -5.28022px 0px, rgb(157, 96, 28) 5.30051px -4.57215px 0px,
    rgb(157, 96, 28) 5.89746px -3.77094px 0px, rgb(157, 96, 28) 6.37426px -2.89289px 0px,
    rgb(157, 96, 28) 6.72119px -1.95591px 0px, rgb(157, 96, 28) 6.93119px -0.979076px 0px;
}
.heading {
  @apply text-[20px] leading-none text-brown-500 uppercase font-black;
}
.paragraph {
  @apply font-normal italic text-black text-xs leading-5 lg:text-sm;
}

.HeroScreen__Slider .swiper-wrapper {
  display: flex;
  align-items: center;
}
.slide-wrap {
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 1024px) {
  .slide-wrap {
    padding: 0 80px;
  }
  .heading {
    @apply text-[40px];
  }
  .paragraph {
    @apply text-sm leading-relaxed;
  }
}
@media (min-width: 1280px) {
  .slide-wrap {
    padding: 0 80px;

    padding: 0 20px;
    max-width: calc(1024px + 40px);
  }
}

.simple-fun-slider.swiper {
  padding-bottom: 40px;
}
.simple-fun-slider.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  border: 2px solid #000000;
  border-radius: 4px;
  background: transparent;
  opacity: 1;
}
.simple-fun-slider.swiper-pagination-bullet-active {
  background: #000000;
}

.text-trim {
  width:calc(100% - 34px); white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block;
}


/* -------------------------------------------------- *
* ---------------- SweetAlert styles ---------------- *
* ---------------------------------------------------- */
.swal2-title {
  color: #FFF !important; text-transform: uppercase !important;
  font-weight: 900 !important;
  padding: 1.5em 1em 0 !important; margin: 0 !important;
}
.swal2-html-container {
  color: #FFF !important;  font-size: 11pt !important; font-weight: 900 !important;
  max-width: 350px !important; margin: auto !important; display: block !important;
}
.swal2-icon {  border: unset !important;  }
.swal2-container.swal2-center > .swal2-popup {
  background: #C67C2B !important;
  background: linear-gradient(180deg, #DA903F 0%, #C67C2B 100%) !important;
  border: #1a1108 4px solid !important;  border-radius: 20px;
  padding: 20px;  padding-top: 40px;
}
.swal2-styled.swal2-confirm {
  background: linear-gradient(180deg, #3C3D47 0%, #000000 100%) !important;
  border: 5px solid #000 !important; border-radius: 20px !important;
  padding-left: 30px; padding-right: 30px;
  width: 100% !important; min-width: 200px !important;
  font-size: 13pt !important; font-weight: 900 !important;
}
#swal_modal_icon { width: 170px !important;  }
#swal_modal_warrior { width: 370px !important;  }


/* -------------------------------------------------- *
* ---------------- Modal Loaders ---------------- *
* ---------------------------------------------------- */
.burn_modal {
  border-radius: 20px !important;
  width: 90vw; max-width: 460px;
  padding: 20px 30px 20px;
  background-color: #C67C2B !important;
  background: linear-gradient(180deg, #DA903F 0%, #C67C2B 100%) !important;
}
.burn_modal .modal_title {
  color: #000 !important; text-transform: uppercase !important;
  font-weight: 900 !important;
  font-size: 14pt !important;
}
.burn_modal .modal_close { margin-right: -20px; }
.burn_modal .modal_form { margin-top: 30px; margin-bottom: 30px; display: block; text-align: center; }
/*.burn_modal img { max-width: 170px; }*/
.loader_label { color: #ffce45; font-size: 14pt; text-transform: uppercase; font-weight: 900;  }

.modal_container {
  background: linear-gradient(180deg, #DA903F 0%, #C67C2B 100%) !important; border: #1a1108 4px solid !important;
  border-radius: 20px !important;
}

.modal_spinner {
  /* add animation rotate for this img  */
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* -------------------------------------------------- *
* ---------------- Burn Modal Manual ---------------- *
* ---------------------------------------------------- */
.burn_modal_overlay {
  position: fixed; top: 0px; left: 0px;
  z-index:999;
  width:100vw; height:100vh;
  background-color: black;
  background-color:rgba(0,0,0,.75)
}

.burn_modal_overlay .burn_container {
  background-color: white;
  border: 2px solid black; border-radius: 20px;
  display: block;
  width: 450px; min-height: 350px;
  z-index: 1001;
  padding: 30px;
  margin: auto; margin-top: 15vh;
}
.burn_modal_overlay .burn_container .close_btn {
  width: 30px; margin-top: -6px; margin-right: -10px; cursor: pointer;
}

.burn_modal_loader {
  box-shadow: unset !important; max-width: 450px; width: 90vw;
  background-color: rgba(0, 0, 0, 0.39);
  border-radius: 20px !important;
  display: block;
  z-index: 1001;
  padding: 30px;
  margin: auto; margin-top: 15vh;
  color: #FFF; font-weight: normal;
}

.modal_scrolled {
  overflow-y: scroll;
  height: 490px;
  padding-right: 15px;
}

.burn_btn:hover { filter: brightness(1.20); }

#chain_storm_logo { max-width: 110px; }

#logged_wallet_btn .logged_wallet { max-width: 120px; font-size: 11pt;}

#logged_wallet_btn .wallet_address { display: block;  }
#logged_wallet_btn .hover_logout{ display: none; }

#logged_wallet_btn:hover .wallet_address { display: none; }
#logged_wallet_btn:hover .hover_logout{ display: block; min-width: 110px; font-size: 11pt; }

.badge {
  margin: auto; padding: 3px 6px; border-radius: 12px; display: block; text-align: center;
  font-size: 9pt; max-width: 70px;
}
.badge.badge_green { background-color: #0B450A; color: #FFF; }
.badge.badge_red { background-color: #8B0000; color: #FFF; }
.mini_text { font-size: 9pt; }